import { getReq, postReq, uploadReq } from '../utils/request'
import { Response } from '../types/api'
import { FormDetail } from '../types/data'

export default {
	sendForm: (data: object) => postReq('/api/v1/form', data),
	sendWhereToPlantForm: (data: object) => postReq('/api/v1/form/where-to-plant', data),
	newsletterSubscribe: (data: object) => postReq('/api/v1/newsletter/subscribe', data),
	uploadFormAttachment: (data: object, header: object) => uploadReq('/api/v1/upload/form-attachment', data, header),
	loadForms: (filters: object): Response<{ [id: string]: FormDetail }> => getReq('/api/v1/forms', filters),
}
