import { PayloadAction, createSlice } from '@reduxjs/toolkit'
import { State } from './reducer'

export type MenuState = {
	selected: string | null
}

const initialState: MenuState = {
	selected: null
}

export const menuSlice = createSlice({
	name: 'menu',
	initialState,
	reducers: {
		select: (state: MenuState, action: PayloadAction<string | null>) => {
			state.selected = action.payload
		}
	}
})

export const getSelectedMenu = (state: State) => state.menu.selected

export const menuActions = menuSlice.actions
