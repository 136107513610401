import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit'

import { State } from './reducer'
import { StateListType, StateType } from '../types/state'
import { Article, ArticleDetail } from '../types/data'

import { articles } from '../api'

export type ThemesState = {
	list: StateListType<{ themes: Article[] }>
	select: StateType<{ themes: Article[] }>
	detail: StateType<ArticleDetail>
}

const initialState: ThemesState = {
	list: {
		data: null,
		isLoading: false,
		isFailure: true
	},
	detail: {
		data: null,
		isLoading: false,
		isFailure: false
	},
	select: {
		data: null,
		isLoading: false,
		isFailure: true
	},
}

const loadThemeList = createAsyncThunk('theme/fetchList',
	async (filters: object) => {
		const { data } = await articles.loadThemes(filters)

		return data
	}
)

export const themesSlice = createSlice({
	name: 'themes',
	initialState,
	reducers: {
		loadThemeDetail: (state: ThemesState, action: PayloadAction<ArticleDetail>) => {
			state.detail.data = action.payload
		},
		loadThemesSelect: (state: ThemesState, action: PayloadAction<{ themes: Article[] }>) => {
			state.select = { isLoading: false, isFailure: false, data: action.payload }
		}
	},
	extraReducers: builder => {
		builder.addCase(loadThemeList.pending, (state) => {
			state.list = { ...state.list, isLoading: true, isFailure: false }
		})

		builder.addCase(loadThemeList.rejected, (state) => {
			state.list = { ...state.list, isLoading: false, isFailure: true }
		})

		builder.addCase(loadThemeList.fulfilled, (state, { payload }) => {
			state.list = { ...state.list, isLoading: false, isFailure: false, data: payload }
		})
	}
})

export const getThemeList = (state: State) => state.themes.list
export const getThemeDetail = (state: State) => state.themes.detail
export const getThemesSelect = (state: State) => state.themes.select

export const themeActions = {
	...themesSlice.actions,
	loadThemeList
}
